import React from "react"
import { Link } from "gatsby"
import  { graphql }  from "gatsby"
import Img from "gatsby-image"

import "./styles.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ItemCard from "../components/itemCard"

const Werke = ({ data }) => {
    const nodes = data.allMarkdownRemark.edges;
    return (
        <Layout>
            <SEO title="Home" />
            <section class="hero is-small">
        <div class="hero-body">
          <div class="container has-text-centered">
            <h1 class="title">
              Hier kannst du durch mein Hand-K-Machtes stöbern:
      </h1>
      <h2 class="subtitle">
              Lass dich auch auf meiner <a className="hero-link" href="https://www.instagram.com/hand_k_macht/">Instagram Seite </a>inspirieren. <br />
              Gerne fertige ich dein Wunschobjekt auf Anfrage an. Schreibe mir dazu einfach eine Nachricht.
      </h2>
          </div>
        </div>
      </section>
      <section className="section">
          <div className="kurse_home">
            <div className="columns">
            {nodes.slice(0,3).map(({node}) => (
                <div className="column is-4">
                <ItemCard  
                  title={node.frontmatter.title} 
                  description={node.html}
                  price={node.frontmatter.price}
                  id={node.frontmatter.werk_id}
                  image={
                    <Img
                      fluid={node.frontmatter.itemImage.childImageSharp.fluid}
                      alt={node.frontmatter.title} />
                    }
                />
              </div>
              
            )) }
             
            </div>
            <div className="columns">
            {nodes.slice(3,6).map(({node}) => (
                <div className="column is-4">
                <ItemCard  
                  title={node.frontmatter.title} 
                  description={node.html}
                  price={node.frontmatter.price}
                  id={node.frontmatter.werk_id}
                  image={
                    <Img
                      fluid={node.frontmatter.itemImage.childImageSharp.fluid}
                      alt={node.frontmatter.title} />
                    }
                />
              </div>
              
            )) }
             
            </div>
            <div className="columns">
            {nodes.slice(6,9).map(({node}) => (
                <div className="column is-4">
                <ItemCard  
                  title={node.frontmatter.title} 
                  description={node.html}
                  price={node.frontmatter.price}
                  id={node.frontmatter.werk_id}
                  image={
                    <Img
                      fluid={node.frontmatter.itemImage.childImageSharp.fluid}
                      alt={node.frontmatter.title} />
                    }
                />
              </div>
              
            )) }
             
            </div>
            <div className="columns">
            {nodes.slice(9,12).map(({node}) => (
                <div className="column is-4">
                <ItemCard  
                  title={node.frontmatter.title} 
                  description={node.html}
                  price={node.frontmatter.price}
                  id={node.frontmatter.werk_id}
                  image={
                    <Img
                      fluid={node.frontmatter.itemImage.childImageSharp.fluid}
                      alt={node.frontmatter.title} />
                    }
                />
              </div>
              
            )) }
             
            </div>
          </div>
        </section>
        </Layout>
    );

}

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 400, maxHeight: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
query {
  allMarkdownRemark(
    filter: { fileAbsolutePath: { regex: "/markdown-werke/" }  }
    sort: {fields: frontmatter___werk_id order: DESC}
    ) {
    edges {
      node {
        html
        frontmatter {
          werk_id
          title
          price
          itemImage {
            ...squareImage
          } 
        }
      }
    }
  }
}
  `

export default Werke
