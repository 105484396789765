import React from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"

const ItemCard = (props) => {

    return (
        <div class="card bm--card-equal-height">
            <div class="card-image">
                    {props.image}
            </div>
            <div class="card-content">
                <div class="media">
                    <div class="media-content">
    <h4 class="title is-4">{props.title}</h4>
                        <div dangerouslySetInnerHTML={{ __html: props.description }} />
                    </div>
                </div>
            </div>
            <footer class="card-footer">
                <p class="card-footer-item">
                    <span>
                        <Link 
                            className="button is-primary" 
                            to="/kontakt/" 
                            state={{ typeRequest: "werke", typeWerk: props.title + props.id }}>Anfragen</Link>
                    </span>
                </p>
                {/* <p class="card-footer-item">
                    <span>
                        <strong>Preis:</strong> {props.price}
                    </span>
                </p> */}
            </footer>
        </div>
    )
}

export default ItemCard